import Container from '@/components/Container';
import Section from '@/components/Section';
import SiteMap from '@/components/SiteMap';
import { Route, ServerRoute, serverToRoute } from '@/config/routes';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

interface HeaderProps {
  transparent?: boolean;
  routePath?: Route[];
  hideGnb?: boolean;
}

const SiteMapPage: FC<HeaderProps> = ({ routePath }) => {
  const {
    allMenu: { nodes: serverMenus },
  } = useStaticQuery<{
    allMenu: { nodes: ServerRoute[] };
  }>(graphql`
    query {
      allMenu {
        nodes {
          id
          level
          menuCode
          menuName
          menuUrl
          openYn
          offspring {
            level
            menuCode
            menuParentCode
            menuColumnOrder
            menuName
            menuUrl
            openYn
            offspring {
              level
              menuCode
              menuName
              menuParentCode
              menuUrl
              openYn
            }
          }
        }
      }
    }
  `);
  const menus = serverMenus.map(serverToRoute);
  return (
    <LayoutWithoutTitle hideMargin>
      <Section>
        <Container>
          <SiteMap />
        </Container>
      </Section>
    </LayoutWithoutTitle>
  );
};

export default SiteMapPage;
